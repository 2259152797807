/**
  Azo Sans Bold
  font-family: azo-sans-web, sans-serif;
  font-weight: 700;
  font-style: normal;

  Azo Sans Bold Italic
  font-family: azo-sans-web, sans-serif;
  font-weight: 700;
  font-style: italic;

  Azo Sans Italic
  font-family: azo-sans-web, sans-serif;
  font-weight: 400;
  font-style: italic;

  Azo Sans Regular
  font-family: azo-sans-web, sans-serif;
  font-weight: 400;
  font-style: normal;
  Azo Sans Uber Regular
  font-family: azo-sans-uber, sans-serif;
  font-weight: 400;
  font-style: normal;
**/

body,
p,
div {
  font-family: azo-sans-web, sans-serif;
}

h1 {
  font-family: cuisine, sans-serif;
  font-style: normal;
  font-size: 28px;
  color: tomato;
}
h2,
h3,
h4 {
  font-family: azo-sans-web, sans-serif;
}
h2 {
  font-size: 18px;
}
h3 {
  font-size: 16px;
}
h4 {
  font-size: 14px;
}

.header-tabs {
  font-family: azo-sans-web, sans-serif;
  font-size: 14px;
  font-weight: 700;
}
